import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import graphic3 from "../../images/mytennisroster/mytennisroster-logo.svg"
import styled from "styled-components"

const Wrapper = styled.section`
  .grid2 {
    grid-template-columns: repeat(2, 1fr);
  }
  .rings {
    max-width: 600px;
  }
  .screens {
    margin-top: 0;
  }
  .link {
    color: #52aabf;
  }
  .client-logo {
    height: 40px;
  }
  @media only screen and (max-width: 576px) {
    .user {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 10px;
    }
    .user p {
      font-size: 12px;
    }
    .users h5 {
      font-size: 0.95rem;
    }
    .persona {
      margin-bottom: 0.5rem;
    }
    .areas {
      grid-template-columns: repeat(1, 1fr);
    }
    .screen {
      margin-bottom: 1rem;
    }
  }
`
const SecondPage = () => (
  <Layout>
    <Seo title="Page two" />
    <Wrapper>
      <div className="project-header">
        <StaticImage
          src="../../images/mytennisroster/mytennisroster-hero.jpg"
          alt="Project"
          placeholder="dominantColor"
          className="hero"
        />
        <div className="project-title">
          <div className="client">
            <img className="client-logo" alt="logo" src={graphic3} />
            <p>/ mobile app design</p>
          </div>
          <div className="desc">
            <h2>Roster Management Mobile App</h2>
            <p>
              designing and prototyping a roster management tool to help coaches
              and players track team positions
            </p>
          </div>
        </div>
      </div>
      <div className="highlights">
        <h5>Project Scope:</h5>
        <ul className="scope">
          <li>Concept</li>
          <li>Workflow</li>
          <li>Wireframe</li>
          <li>Visual Design</li>
          <li>Prototype</li>
        </ul>
      </div>
      <div className="highlights">
        <h5>Overview:</h5>
        <p>
          Individual sports such as golf, swimming, and tennis use ladder
          systems to rank player positions on the team roster. Being
          specifically familiar with tennis teams, I saw a need for a simple
          mobile app that allows coaches to build, manage and update roster
          positions based on tryouts and match performances throughout the
          season. The proof of concept app provides the ability to create teams
          and allow for dynamic roster updates. It stores player information and
          performance stats and offers a convenient communication channel for
          notifications and logistics. The app also allows players to initiate
          challenge matches and have full visibility into their position on the
          roster.
        </p>
      </div>
      <div className="highlights">
        <h5>Project Goals:</h5>
        <ul>
          <li>Create proof of concept for new dynamic roster mobile app</li>
          <li>
            Design a simple and elegant tool that benefits coaches and players{" "}
          </li>
          <li>
            Build a tool that can support dynamic changes in player rankings
          </li>
          <li>Drive transparency in player rankings and placements</li>
          <li>Improve process of roster management and player participation</li>
        </ul>
      </div>
      <div className="highlights">
        <h5>My Role:</h5>
        <ul>
          <li>
            Facilitate discovery sessions with tennis professionals and high
            school coaches
          </li>
          <li>
            Research industry standards, create needs analysis for roster
            management toolset
          </li>
          <li>Document feature requirements, personas, workflows</li>
          <li>User experience and visual design development</li>
          <li>Build InVisual prototype proof of concept</li>
          <li>
            Manage mobile app development cycle, including dynamic roster
            algorithm
          </li>
        </ul>
      </div>
      <br />
      <div className="screens">
        <div className="project-label">
          <p>Brand Design Language Development</p>
        </div>
        <StaticImage
          src="../../images/mytennisroster/mytennisroster-1.png"
          alt="Project"
          placeholder="dominantColor"
          quality={100}
          className="screen"
        />
        <StaticImage
          src="../../images/mytennisroster/mytennisroster-2.jpg"
          alt="Project"
          placeholder="dominantColor"
          quality={100}
          className="screen"
        />
        <StaticImage
          src="../../images/mytennisroster/mytennisroster-3.jpg"
          alt="Project"
          placeholder="dominantColor"
          quality={100}
          className="screen"
        />
        <StaticImage
          src="../../images/mytennisroster/mytennisroster-4.jpg"
          alt="Project"
          placeholder="dominantColor"
          quality={100}
          className="screen"
        />
      </div>
      <div className="footer-nav">
        <Link to="/">&#x2190; Recent work</Link>
        <span></span>
        <Link to="/work/glasbox">Next Project &#8594; </Link>
      </div>
    </Wrapper>
  </Layout>
)

export default SecondPage
